import { useState } from "react";
import { Link, useLocation  } from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import Container from "../../common/Container";
import "antd/dist/antd.css";
import "./index.css";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  GlowImg,
} from "./styles";

function Header(){
  const [visible, setVisibility] = useState(false);

  
 /* const { active, account, library, connector, activate, deactivate } = useWeb3React()
  <Button onClick={connect} >Connect to MetaMask</Button>
      {active ? <p>Connected with {account}</p> : <p>no funca la wea</p>}
      <Button onClick={disconnect} >Disconnect</Button>
  */
  useLocation()
  var Home = true;
  if((window.location.href.indexOf("/mint") > -1) || (window.location.href.indexOf("/myfrens") > -1) || (window.location.href.indexOf("/rewards") > -1))
  {
    Home = false
  }
  

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(false);
    window.scrollTo({top: 0});
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
      
    };
    return (
      <>
          
          
          
          
          
        
        {/*<CustomNavLinkSmall >
          <a href='https://embed.flair.dev/1/0x6769f12aef04afef3bcbf803c71c8bc439e0efaa/user:tiered-sales' target='_blank' rel='noopener noreferrer'>
            <b>MINT</b>
            </a>
          </CustomNavLinkSmall>]*/}
          { Home ? 
        <><CustomNavLinkSmall onClick={() => scrollTo("about")}>
        <Span style={{color:"#fff",fontSize:"1.5rem"}}><b>ABOUT</b></Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("roadmap")}>
        <Span style={{color:"#fff",fontSize:"1.5rem"}}><b>ROADMAP</b></Span>
        </CustomNavLinkSmall></>
        :
        <>
        <CustomNavLinkSmall >
        <Span><Link to="/" onClick={onClose}><b>HOME</b></Link></Span>
    </CustomNavLinkSmall>
        </>
        
        }
        <CustomNavLinkSmall >
          <a href='https://frenly-frens-nfts.gitbook.io/frenly-frens-nft-whitepages/' target='_blank' rel='noopener noreferrer'>
            <b>WHITEPAGES</b>
            </a>
          </CustomNavLinkSmall>
        <CustomNavLinkSmall >
            <Span><Link to="/myfrens" onClick={onClose}><b>MY FRENS</b></Link></Span>
        </CustomNavLinkSmall>
        
        
        
        {/*
        <CustomNavLinkSmall >
            <Span ><Link style={{fontSize:"28px"}} to="/" onClick={onClose}><b>MINT</b></Link></Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
            <Span ><Link style={{fontSize:"28px"}} to="/myNFT" onClick={onClose}><b>MY REJECTS</b></Link></Span>
        </CustomNavLinkSmall>
        */}


      </>
    );
  };

  return (

    
    <HeaderSection style={{zIndex:5}} id="header">
      <Container>
        <Row justify="space-between">
          <Col span={4} >
            <LogoContainer to="/" aria-label="homepage" onClick={onClose} >
              <GlowImg src="collection.png" alt="logoalt.png" style={{display: "block",maxWidth:"300px",maxHeight:"100px",width:"auto",height:"auto"}} /> 
            </LogoContainer>
          </Col>
          
          
          
            
            <NotHidden>
            <Col span={24} style={{textAlign:"right"}}>
              <MenuItem />
              <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://opensea.io/collection/frenly-frens-nft" target="_blank" rel="noreferrer">
                  <img src="/img/ops.svg" alt="opensea"  style={{width:"32px",height:"32px"}}/>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://twitter.com/FrenlyFrensNFT" target="_blank" rel="noreferrer">
                  <img src="/img/twt.svg" alt="twt.svg"  style={{width:"32px",height:"32px"}}/>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://t.me/FrenlyFrens" target="_blank" rel="noreferrer">
                    <img src="/img/tg.svg" alt="tg.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>
              </CustomNavLinkSmall>
              
              <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://discord.gg/VJmjTsWUWA" target="_blank" rel="noreferrer">
                    <img src="/img/dsc.svg" alt="dsc.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>
              </CustomNavLinkSmall>
              {/*
              <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://tofunft.com/collection/astar-maidens/items" target="_blank" rel="noreferrer">
                    <img src="/img/tofu.svg" alt="tofu.svg"  style={{width:"auto",height:"32px"}}/>
                  </a>
              </CustomNavLinkSmall>*/}
              
              </Col>
              
              </NotHidden>
              
          
          <Burger onClick={showDrawer}>
          <Col span={12}>
            <Outline />
            </Col>
          </Burger>
          
          
          
        </Row>
        
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
                
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
          <div style={{textAlign:"center", paddingTop:"16px"}}>
                <a style={{justifyContent:"center",paddingRight:"16px"}}  href="https://opensea.io/collection/frenly-frens-nft" target="_blank" rel="noreferrer">
                  <img src="/img/ops.svg" alt="opensea"  style={{width:"32px",height:"32px"}}/>
                </a>
                  <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://twitter.com/FrenlyFrensNFT" target="_blank" rel="noreferrer">
                    <img src="/img/twt.svg" alt="twt.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>
                  <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://t.me/FrenlyFrens" target="_blank" rel="noreferrer">
                    <img src="/img/tg.svg" alt="tg.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>
                  
                  <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://discord.gg/VJmjTsWUWA" target="_blank" rel="noreferrer">
                    <img src="/img/dsc.svg" alt="dsc.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>
                  {/*
                <a style={{justifyContent:"center"}} href="https://tofunft.com/collection/astar-maidens/items" target="_blank" rel="noreferrer">
                    <img src="/img/tofu.svg" alt="tofu.svg"  style={{width:"auto",height:"32px"}}/>
                  </a>
            */}
          </div>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
