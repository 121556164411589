import Container from "../../common/Container";
import { Row, Col } from "antd";
import {
  DeskDiv,
  MobileDiv,
  GlowImg,
} from "./styles";


const Footer = () => {
  return (
    <>
      <div style={{backgroundColor:"#000",color:"#fff",paddingTop:"36px",paddingBottom:"36px"}}>
        <Container>
        <DeskDiv>
        <Row justify="space-between">
          <Col>
            <Row style={{paddingBottom:"16px"}}>
              <GlowImg src="collection.png" alt="logoalt.png" />
            </Row>
            <Row >
            <p style={{color:"#fff"}}>© 2023, Frenly Frens</p>
            </Row>
        
          </Col>
          <Col>
              <Row style={{paddingBottom:"8px",justifyContent:"center",textAlign:"center"}}>
                <p style={{color:"#fff"}}>Join our socials!</p>
              </Row>

              <Row style={{justifyContent:"center"}}>

                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://twitter.com/FrenlyFrensNFT" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/twt.svg" alt="twt.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://t.me/FrenlyFrens" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/tg.svg" alt="tg.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://discord.gg/VJmjTsWUWA" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/dsc.svg" alt="dsc.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>


              </Row>
              


              
              
          </Col>
        </Row>
        </DeskDiv>
        <MobileDiv>
            <Row style={{paddingBottom:"16px",justifyContent:"center"}}>
              <GlowImg src="collection.png" alt="logoalt.png"/>
            </Row>
            <Row style={{justifyContent:"center"}}>
            <p style={{color:"#fff"}}>© 2022, Frenly Frens</p>
            </Row>


              <Row style={{paddingBottom:"0px",justifyContent:"center",textAlign:"center"}}>
                <p style={{color:"#fff"}}>Join our socials!</p>
              </Row>
              <Row style={{justifyContent:"center"}}>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://twitter.com/FrenlyFrensNFT" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/twt.svg" alt="twt.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://t.me/FrenlyFrens" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/tg.svg" alt="tg.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>
                <a style={{justifyContent:"center", paddingRight:"12px"}} href="https://discord.gg/VJmjTsWUWA" target="_blank" rel="noreferrer">
                <Col>
                  
                    <img src="/img/dsc.svg" alt="dsc.svg"  style={{width:"48px",height:"48px", padding:"6px"}}/>
                  
                </Col>
                </a>


              </Row>


        </MobileDiv>
        
        </Container>
      </div>

    </>
  );
};

export default (Footer);
