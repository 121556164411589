import { lazy } from "react";
import {
  BackgroundContainer,
  Desktop,
  Mobile,
} from "./styles";


const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
//const TokenomicsBlock = lazy(() => import("../../components/Home/TokenomicsBlock"));
const AboutBlock = lazy(() => import ("../../components/Mint/AboutBlock"));
const MarqueeBlock = lazy(() => import ("../../components/Mint/MarqueeBlock"));
//const UtilityBlock = lazy(() => import ("../../components/Mint/UtilityBlock"));
const TeamBlock = lazy(() => import ("../../components/Mint/TeamBlock"));
const MintBlock = lazy(() => import ("../../components/Mint/MintBlock"));
const RoadmapDesktopBlock = lazy(() => import ("../../components/Mint/RoadmapDesktopBlock"));
const RoadmapMobileBlock = lazy(() => import ("../../components/Mint/RoadmapMobileBlock"));
//const ChartBlock = lazy(() => import ("../../components/Home/ChartBlock"));


const Home = () => {
  return (
    <>
    <BackgroundContainer>

        <div style={{
              
        }}>
          <Container>
            <MintBlock/>
          </Container>
        </div>
        <div style={{
              
            }}>
              <Container>
                <AboutBlock/>
              </Container>
            </div>

                <MarqueeBlock/>

                <div style={{
              
            }}>
            </div>
              <Container>
                <Desktop>
                <RoadmapDesktopBlock/>
                </Desktop>
                <Mobile>
                <RoadmapMobileBlock/>
                </Mobile>
              </Container>

            <div style={{
              
            }}>
              <Container>
                <TeamBlock/>
              </Container>
            </div>
            
              

        <ScrollToTop />
    </BackgroundContainer>
    </>
  );
};
export default Home;
