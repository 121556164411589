import styled from "styled-components";
import { keyframes } from 'styled-components'
//background: radial-gradient(circle, rgba(83,64,240,1) 52%, rgba(83,64,240,0.8158613787311799) 100%);
//background-attachment:fixed;


export const BackgroundContainer = styled("section")`
background: rgb(0,0,0,1);

background-attachment: fixed;

  
  overflow-x: hidden;


`;

export const Mobile = styled("section")`

  
  @media only screen and (min-width: 1025px) {
    display:none;
  }

`;
export const Desktop = styled("section")`

  
  @media only screen and (max-width: 1025px) {
    display:none;
  }

`;