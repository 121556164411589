import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: rgba(0,0,0,1);
  padding: 24px;
`;


export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }
`;


export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const DeskDiv = styled("div")`
  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;
export const MobileDiv = styled("div")`
  display:none;
  @media only screen and (max-width: 1025px) {
    display: block;
  }
`;

export const GlowImg = styled("img")`
  filter: drop-shadow(0px 0px 5px #64f933);
  display: block;
  max-width:400px;
  max-height:100px;
  width:auto;
  height:auto;
  @media only screen and (max-width: 1025px) {
    max-width:250px;
    max-height:150px;
  }
`;